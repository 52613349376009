/* eslint-disable complexity */
import React, { FC } from 'react';

const getURLParameter = (qrString: string, paramName: string): string => {
  const qr = qrString.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`);
  const results = regex.exec(qr);

  return results && results.length > 0
    ? decodeURIComponent(results[1].replace(/\+/g, ' '))
    : '';
};

const setDataToLocalStorage = (key: string, value: string): void => {
  window.localStorage.setItem(key, value);
};

const getDataFromLocalStorage = (key: string) => {
  return window.localStorage.getItem(key);
};


// check is development or production mode.
const isDev = (): Boolean => {
  const env = process.env.NODE_ENV;
  return env === 'development';
};

const getTime = (date?: string) => {
  if (!date) return '';

  return new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

const getPageName = () => {
  const path = window.location.hash;
  return path
}

const isContestNeutral = (type: string | undefined | null) => {
  if (type)
    return ['NEUTRAL', 'CHALLENGE_CREATED', 'CHALLENGE_CONTINUED'].includes(type)
  return false
}


const utils = {
  isDev,
  getTime,
  getURLParameter,
  setDataToLocalStorage,
  getDataFromLocalStorage,
  getPageName,
  isContestNeutral
};

export default utils;
