export const firebaseDevConfig = {
    apiKey: "AIzaSyAAeUakFj9fISVunm3fuOEKc82Qn4G7hLk",
    authDomain: "wakao-app.firebaseapp.com",
    projectId: "wakao-app",
    storageBucket: "wakao-app.appspot.com",
    messagingSenderId: "838467946360",
    appId: "1:838467946360:web:02449959d549f64d67ea8d",
    measurementId: "G-KV0RNQ5JTW"
};

export const firebaseProdConfig = {
    apiKey: "AIzaSyCgiq0oHv-vu6Sf2EIT49eECdm4B0o3XN8",
    authDomain: "wakao-production.firebaseapp.com",
    projectId: "wakao-production",
    storageBucket: "wakao-production.appspot.com",
    messagingSenderId: "877124451922",
    appId: "1:877124451922:web:059244074423bf192da98b",
    measurementId: "G-R6YEDNF8VQ"
};