import { Suspense, lazy, FC, createRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

import theme from '../MuiWrapper/theme';
import ErrorBoundary from './components/Atom/ErrorBoundary/ErrorBoundary';
import Navbar from './components/Atom/Navbar';
import Footer from './components/Atom/Footer';
import { firebaseDevConfig, firebaseProdConfig } from '../firebaseConfig';

const GlobalLoader = lazy(() => import('./components/Atom/Loader'));

const HomePage = lazy(() => import('./pages/HomePage'));
const CommunityGuidelinesPage = lazy(
  () => import('./pages/CommunityGuidelinesPage')
);
const SupportPage = lazy(() => import('./pages/SupportPage'));
const TermsAndConditionsPage = lazy(
  () => import('./pages/TermsAndConditionsPage')
);
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const Top10Page = lazy(() => import('./pages/Top10'));

const App: FC = () => {
  const getFallBackLoader = () => <GlobalLoader />;
  initializeApp(
    process.env.NODE_ENV == 'production'
      ? firebaseProdConfig
      : firebaseDevConfig
  );

  return (
    <>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={getFallBackLoader()}>
            <StyledEngineProvider injectFirst>
              {/* Your component tree. Now you can override Material-UI's styles. */}
              <Router>
                <Navbar />

                <Routes>
                  <Route path="/top-10" element={<Top10Page />} />
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/community-guidelines"
                    element={<CommunityGuidelinesPage />}
                  />
                  <Route path="/support" element={<SupportPage />}></Route>
                  <Route
                    path="/terms&conditions"
                    element={<TermsAndConditionsPage />}
                  />
                  <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicyPage />}
                  />
                </Routes>
                <Footer />
              </Router>
            </StyledEngineProvider>
          </Suspense>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
