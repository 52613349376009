import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NavLogo from '../../../../asset/logo.svg';
import { useDeviceDetect } from '../../../Utils';

const NavContainer = styled(Box)<{ isMobile: Boolean; screenIsAtTop: Boolean }>`
  padding: ${(props) =>
    props.screenIsAtTop
      ? '40px 60px'
      : `${props.isMobile ? '12px 60px' : '20px 60px'}`};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  background-color: ${(props) => (props.screenIsAtTop ? 'inherit' : '#020104')};
  border-radius: 0px 0px 16px 16px;
  transition: all 0.3s ease;
`;

const NavBar = () => {
  const { isMobile } = useDeviceDetect();
  const [screenIsAtTop, setScreenIsAtTop] = useState<boolean>(true);
  let navigate = useNavigate();

  window.addEventListener('scroll', () => {
    if (screenIsAtTop && window.scrollY >= 40) setScreenIsAtTop(false);
    else if (!screenIsAtTop && window.scrollY < 40) {
      setScreenIsAtTop(true);
    }
  });

  return (
    <NavContainer isMobile={isMobile} screenIsAtTop={screenIsAtTop}>
      <img
        src={NavLogo}
        alt="Wakao"
        height={isMobile ? '24px' : '28px'}
        onClick={() => navigate('/')}
        style={{ cursor: 'pointer' }}
      />
    </NavContainer>
  );
};

export default NavBar;
