import styled from '@emotion/styled';
import { Box, Divider, IconButton, Typography } from '@mui/material';

import WakaoLogo from '../../../../asset/logo.svg';
import Copyright from '../../../../asset/copyright.svg';
import IntagramLogo from '../../../../asset/instagram.svg';
import YoutubeLogo from '../../../../asset/youtube.svg';
import LinkedInLogo from '../../../../asset/linkedin.svg';
import FooterDotsIcon from '../../../../asset/backgrounds/footerdots.svg';
import FooterVectorIcon from '../../../../asset/backgrounds/footer_1.svg';
import { useDeviceDetect } from '../../../Utils';
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled(Box)<{ isMobile: Boolean }>`
  padding: ${(props) => (props.isMobile ? '90px 40px' : '140px 120px')};
  background-color: #7536ff;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  // z-index: -5;
`;
const FooterVector = styled.img<{ isMobile: Boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.isMobile ? '228px' : '451px')};
  // z-index: -1;
`;
const FooterDots = styled.img<{ isMobile: Boolean }>`
  position: absolute;
  transform: ${(props) => props.isMobile && 'rotate(90deg)'};
  bottom: ${(props) => (props.isMobile ? '64px' : '32px')};
  right: ${(props) => (props.isMobile ? '-36px' : '32px')};
`;

const LinkText = styled(Typography)<{ isMobile: Boolean }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #ffffff;
  cursor: pointer;
  margin-top: ${(props) => (props.isMobile ? '24px' : '0')};
  text-align: center;
  display: inline-block;
  text-decoration: none;
  &:hover {
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    margin-top: 2px;
    height: 2px;
    background: #d2f02c;
    transition: width 0.3s;
  }
`;
const SocialButton = styled(IconButton)<{ isMobile: Boolean }>`
  margin: ${(props) => (props.isMobile ? '0 10px' : '0 4px')};
  width: ${(props) => (props.isMobile ? '52px' : '44px')};
  height: ${(props) => (props.isMobile ? '52px' : '44px')};
`;

const Footer = () => {
  const { isMobile } = useDeviceDetect();
  let navigate = useNavigate();

  const navigateTo = (link: string) => {
    if (link.startsWith('https')) {
      window.open(link, '_blank');
      return;
    }
    navigate(link);
  };

  return (
    <FooterContainer isMobile={isMobile}>
      <FooterVector src={FooterVectorIcon} isMobile={isMobile} />
      <Box
        display="flex"
        flexDirection="column"
        marginRight={isMobile ? '0' : '15%'}
      >
        <img
          src={WakaoLogo}
          alt="Wakao"
          width={isMobile ? '128px' : '192px'}
          style={{ marginBottom: '24px' }}
        />
        {!isMobile && (
          <img
            src={Copyright}
            alt="Wakao"
            width="118px"
            style={{ marginLeft: '4px' }}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          marginBottom={isMobile ? '0' : '52px'}
        >
          <LinkText
            isMobile={isMobile}
            onClick={() =>
              navigateTo(
                'https://www.notion.so/About-Wakao-df69b888f36b4dd18ae9c5ae130b2312'
              )
            }
          >
            About
          </LinkText>
          {/* <LinkText
            isMobile={isMobile}
            onClick={() =>
              navigateTo(
                'https://www.notion.so/Careers-at-Wakao-63ed9ea9e8c247ac89c1440caabbfb72'
              )
            }
          >
            Careers
          </LinkText> */}
          {/* <LinkText isMobile={isMobile}>Blog</LinkText> */}
          <LinkText
            isMobile={isMobile}
            onClick={() => navigateTo('community-guidelines')}
          >
            Community Guidelines
          </LinkText>
          <LinkText isMobile={isMobile} onClick={() => navigateTo('support')}>
            Support
          </LinkText>
        </Box>
        {isMobile && (
          <Divider
            style={{
              backgroundColor: '#8255E8',
              height: '1px',
              width: '100px',
              marginTop: '24px',
            }}
          />
        )}
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            width={isMobile ? 'auto' : '50%'}
            justifyContent="space-between"
          >
            <LinkText
              isMobile={isMobile}
              onClick={() => navigateTo('terms&conditions')}
            >
              Terms & Conditions
            </LinkText>
            <LinkText
              isMobile={isMobile}
              onClick={() => navigateTo('privacy-policy')}
            >
              Privacy Policy
            </LinkText>
          </Box>
          <Box marginTop={isMobile ? '48px' : '0'}>
            <SocialButton
              isMobile={isMobile}
              onClick={() => navigateTo('https://www.instagram.com/wakao.app/')}
            >
              <img src={IntagramLogo} width={isMobile ? '40px' : '32px'} />
            </SocialButton>
            <SocialButton
              isMobile={isMobile}
              onClick={() =>
                navigateTo(
                  'https://youtube.com/channel/UC8GcEjGExzvPq_r3iGLc8YQ'
                )
              }
            >
              <img src={YoutubeLogo} width={isMobile ? '40px' : '32px'} />
            </SocialButton>

            <SocialButton
              isMobile={isMobile}
              onClick={() =>
                navigateTo('https://www.linkedin.com/company/wakao-app')
              }
            >
              <img src={LinkedInLogo} width={isMobile ? '40px' : '32px'} />
            </SocialButton>
          </Box>
          {isMobile && (
            <img
              src={Copyright}
              alt="Wakao"
              width="102px"
              style={{ marginTop: '24px' }}
            />
          )}
        </Box>
      </Box>
      <FooterDots src={FooterDotsIcon} alt="." isMobile={isMobile} />
    </FooterContainer>
  );
};

export default Footer;
